.react-toast-notifications__container{
  z-index: 99999 !important;
}
.ql-editor{
  height:500px;
}
.verse-row {
  margin-bottom: 10px;
  border-bottom: 1px solid #DDDDDD;
  padding: 12px;
}
